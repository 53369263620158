import React from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useGSAP } from "@gsap/react";

function Section5() {
    const isMobile = window.innerWidth <= 1200;    
    const backgroundImageStyle = {
        backgroundImage:   !isMobile && "url('https://rr-media-files.s3.ap-south-1.amazonaws.com/image+305.png')",
        backgroundSize: isMobile ? '' : '0%',
        height: '100vh', // Fixed height to prevent collapse
    opacity:isMobile ? '1' : '100%'
    };

    
    useGSAP(() => {
        if(!isMobile){
 

            gsap.to('#section-5', {
                backgroundSize: '100%',
                duration: 5,
                scrollTrigger: {
                    trigger: '#section-5',
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                    pin:true
                
                }
            });
        }else{
    
            gsap.to('#image-scale-5', {
                opacity: 1,
                duration: 5,
                scrollTrigger: {
                    trigger: '#section-5',
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                    pin:true
                }
            });
        }
   
    }, []);
  return (
    <div className='min-h-screen flex relative flex-col justify-center items-center px-[20px] sm:px-[0] bg-cover bg-bottom  bg-no-repeat' 
    id='section-5'
    style={backgroundImageStyle}
    >
      <img  src='https://rr-media-files.s3.ap-south-1.amazonaws.com/image+305.png' alt='' className='sm:hidden object-cover w-full h-full absolute top-0 left-0' id='image-scale-5' style={{
                opacity:0
            }}/>

    <div className="w-[260px] sm:w-[366px] relative"><span className="text-zinc-100 text-base sm:text-2xl font-normal font-['Metropolis'] leading-[31.20px]">In </span><span className="text-violet-400 text-5xl sm:text-9xl font-light font-['Canela'] leading-[62.40px] sm:leading-[166.40px]">2012</span><span className="text-zinc-100 text-base sm:text-2xl font-normal font-['Metropolis'] leading-tight sm:leading-[31.20px]">, RR ventured into new territory with the launch of its Ahmedabad office. </span></div>
    {/* <div className='hidden sm:block'>
        <img className="w-[402px] h-[267.93px]" src="https://rr-media-files.s3.ap-south-1.amazonaws.com/image+305.png" />
    </div> */}
</div>
  )
}

export default Section5
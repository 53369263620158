import React, { useState, useEffect } from 'react';
import GradientSection from './GradientSection'
import Section1 from './Section1';
import Section3 from './Section3';
import Section5 from './Section5';
import Section6 from './Section6';


const Landing = () => {



    return (
        <div className='pt-[3.5rem] sm:pt-[5rem] relative bg-gray-900'>
            <Section1 />
            {/* <div className='min-h-screen flex justify-center items-center'>
                <div className="w-[253px] sm:w-[1052px]  text-white text-base sm:text-2xl font-normal font-['Metropolis'] leading-[31.20px] ">RR began its journey by establishing a strong foundation with a primary focus on Office Leasing Services at Ambadeep Building, CP. With a dynamic financial landscape, RR evolved and ventured into uncharted territory in 2002 by launching its Investment Advisory Vertical.</div>
            </div>
            <Section3 />
            <div>
                <GradientSection />
            </div>
            <div className='sm:pl-[79px] px-[16.5px] sm:px-0 pt-[19px] sm:pt-[74px] min-h-screen grid grid-cols-1 sm:grid-cols-2'>
                <div>
                    <img className="w-full sm:w-[767.67px] h-[239px]" src="https://rr-media-files.s3.ap-south-1.amazonaws.com/image+302.png" />
                    <div className=" text-zinc-100 text-base sm:text-2xl font-normal font-['Metropolis'] leading-tight sm:leading-[31.20px] my-[12px] sm:my-[24.5px]"> RR expanded its reach by launching the Gurgaon Time Tower office in the prominent business hub of NCR. The following year, RR held its grand Annual Event showcasing a captivating blend of fashion, dance, and more.</div>
                    <div className=" text-white text-2xl sm:text-[64px] font-light font-['Canela'] leading-[31.20px] sm:leading-[83.20px]">Increasing Our Reach Beyond NCR Horizons In </div>
                    <div className="w-[336px] text-white text-5xl sm:text-9xl font-light font-['Canela'] leading-[62.40px] sm:leading-[166.40px] mt-[12px] sm:mt-0">2009</div>
                </div>
                <div className='relative'>
                    <div className='absolute bottom-0 right-0'>

                        <img className=" w-[161.84px] h-[202.30px]  sm:w-[475.20px]  sm:h-[594px] sm:rounded-br-[38px]" src="https://rr-media-files.s3.ap-south-1.amazonaws.com/Rectangle+64.png" />
                    </div>
                </div>
            </div>
            <Section5 />
            <Section6 />
            <div className='min-h-screen flex flex-col justify-center items-center px-[20px] sm:px-0'>
                <div className='flex flex-col sm:flex-row items-center gap-[10px]'>
                    <div className="sm:w-[552px]"><span className="text-white text-base sm:text-2xl font-normal font-['Metropolis'] leading-tight sm:leading-[31.20px]">The year</span><span className="text-violet-400 text-5xl sm:text-9xl font-light font-['Canela'] sm:leading-[166.40px]">2016</span><span className="text-white text-base sm:text-2xl font-normal font-['Metropolis'] leading-tight sm:leading-[31.20px]">, became iconic for RR with the memorable annual Galaxy of RR event. This spectacular gathering, marked by glamour and grandeur, celebrated our achievements and marked a pinnacle in its journey. <br /></span></div>
                    <img className="sm:w-[304.05px] h-[218.58px] w-full" src="https://rr-media-files.s3.ap-south-1.amazonaws.com/image+303.png" />
                </div>
            </div>
            <div className='min-h-screen flex flex-col items-center justify-center  px-[20px] sm:px-[0px]'>
                <div className="sm:w-[1246px] text-violet-400 text-2xl sm:text-9xl font-light font-['Canela'] sm:leading-[166.40px]">Stepping Up With New Technologies And <br />Quarter-Century of RR</div>
                <div className="sm:w-[1246px] text-white text-base sm:text-2xl font-normal font-['Metropolis'] leading-tight sm:leading-[31.20px]">RR adopts new technology to welcome new opportunities and simultaneously expand its reach in residential dealings. As we celebrate our 25th anniversary in 2023, we recognize the dedication of our team to driving success and innovation. Technology and training are the cornerstones that will empower us to shape the industry with growth. Here's to our past, present, and future - a celebration of unity and progress.</div>
            </div> */}
        </div>
    )
}

export default Landing